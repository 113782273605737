<template>
	<a-spin />
</template>
<script>
//import { LoadingOutlined } from "@ant-design/icons-vue";
// import { defineComponent, h } from "vue";
// export default defineComponent({
// 	setup() {
// 		const indicator = h(LoadingOutlined, {
// 			style: {
// 				fontSize: "70px",
// 			},
// 			spin: true,
// 		});
// 		return {
// 			indicator,
// 		};
// 	},
// });
export default {
	name: "Spinner",
};
</script>